/*
naming: "font"-family-weight-size-line_height

check variables below
*/

// Poppins
$font-poppins--16px-1_34: 16px/1.34 "Poppins";
$font-poppins--16px-1_2: 16px/1.2 "Poppins";
$font-poppins--18px-1_2: 18px/1.2 "Poppins";
$font-poppins--18px: 18px "Poppins";

$font-poppins-300-10px: 300 10px "Poppins";
$font-poppins-300-12px-1_24: 300 12px/1.24 "Poppins";
$font-poppins-300-13px-1_5: 300 13px/1.5 "Poppins";
$font-poppins-300-13_5px: 300 13.5px "Poppins";
$font-poppins-300-14px: 300 14px "Poppins";
$font-poppins-300-14px-1_5: 300 14px/1.5 "Poppins";
$font-poppins-300-16px: 300 16px "Poppins";
$font-poppins-300-18px: 300 18px "Poppins";
$font-poppins-300-18px-1_5: 300 18px/1.5 "Poppins";
$font-poppins-300-20px-1_5: 300 20px/1.5 "Poppins";

$font-poppins-400-10_5px-1_5: 400 10.5px/1.5 "Poppins";
$font-poppins-400-12px: 400 12px "Poppins";
$font-poppins-400-12px-1_5: 400 12px/1.5 "Poppins";
$font-poppins-400-13px-1_24: 400 13px/1.24 "Poppins";
$font-poppins-400-13px-1_5: 400 13px/1.5 "Poppins";
$font-poppins-400-14px: 400 14px "Poppins";
$font-poppins-400-14px-1_7: 400 14px/1.7 "Poppins";
$font-poppins-400-15px-1_2: 400 15px/1.25 "Poppins";
$font-poppins-400-16px: 400 16px "Poppins";
$font-poppins-400-16px-1_25: 400 16px/1.25 "Poppins";
$font-poppins-400-16px-1_38: 400 16px/1.38 "Poppins";
$font-poppins-400-16px-1_5: 400 16px/1.5 "Poppins";
$font-poppins-400-16px-1_87: 400 16px/1.87 "Poppins";
$font-poppins-400-17px: 400 17px "Poppins";
$font-poppins-400-18px: 400 18px "Poppins";
$font-poppins-400-18px-1_5: 400 18px/1.5 "Poppins";
$font-poppins-400-20px: 400 20px "Poppins";
$font-poppins-400-25px-1_84: 400 25px/1.84 "Poppins";

$font-poppins-500-10px: 500 10px "Poppins";
$font-poppins-500-12px: 500 12px "Poppins";
$font-poppins-500-12px-1_24: 500 12px/1.24 "Poppins";
$font-poppins-500-13px-1_24: 500 13px/1.24 "Poppins";
$font-poppins-500-14px: 500 14px "Poppins";
$font-poppins-500-15px: 500 15px "Poppins";
$font-poppins-500-14px-1_24: 500 14px/1.24 "Poppins";
$font-poppins-500-14px-1_5: 500 14px/1.5 "Poppins";
$font-poppins-500-16px: 500 16px "Poppins";
$font-poppins-500-16px-1_2: 500 16px/1.2 "Poppins";
$font-poppins-500-16px-1_34: 500 16px/1.34 "Poppins";
$font-poppins-500-16px-1_5: 500 16px/1.5 "Poppins";
$font-poppins-500-18px: 500 18px "Poppins";
$font-poppins-500-18px-1_24: 500 18px/1.24 "Poppins";
$font-poppins-500-18px-1_5: 500 18px/1.5 "Poppins";
$font-poppins-500-20px-1_5: 500 20px/1.5 "Poppins";
$font-poppins-500-22px: 500 22px/1 "Poppins";
$font-poppins-500-22_7px: 500 22.7px/1 "Poppins";
$font-poppins-500-24px-0_8: 500 24px/0.8 "Poppins";
$font-poppins-500-26px-1_2: 500 26px/1.2 "Poppins";

$font-poppins-600-10px: 600 10px "Poppins";
$font-poppins-600-14px: 600 14px "Poppins";
$font-poppins-600-14_4px: 600 14.4px "Poppins";
$font-poppins-600-14_5px-1_5: 600 14.5px/1.5 "Poppins";
$font-poppins-600-15px: 600 15px "Poppins";
$font-poppins-600-16px: 600 16px/1 "Poppins";
$font-poppins-600-16px-1_5: 600 16px/1.5 "Poppins";
$font-poppins-600-17px-1_5: 600 17px/1.5 "Poppins";
$font-poppins-600-18px: 600 18px/1 "Poppins";
$font-poppins-600-18px-1_2: 600 18px/1.2 "Poppins";
$font-poppins-600-18px-1_5: 600 18px/1.5 "Poppins";
$font-poppins-600-18_3px: 600 18.3px/1 "Poppins";
$font-poppins-600-18_5px-1_2: 600 18.5px/1.2 "Poppins";
$font-poppins-600-18_5px-1_5: 600 18.5px/1.5 "Poppins";
$font-poppins-600-19px-1_5: 600 19px/1.5 "Poppins";
$font-poppins-600-20px: 600 20px "Poppins";
$font-poppins-600-24px: 600 24px "Poppins";
$font-poppins-600-25px: 600 25px "Poppins";
$font-poppins-600-28px: 600 28px "Poppins";
$font-poppins-600-32px: 600 32px "Poppins";
$font-poppins-600-36px-1_27: 600 36px/1.27 "Poppins";

$font-poppins-700-9_5px: 700 9.5px "Poppins";
$font-poppins-700-15px-1_2: 700 15px/1.25 "Poppins";
$font-poppins-700-20px: 700 18px "Poppins";
$font-poppins-700-24px-1_24: 700 21px/1.24 "Poppins";
$font-poppins-700-30px: 700 30px "Poppins";
$font-poppins-700-35px-1_2: 700 35px/1.2 "Poppins";

// Volkhov
$font-volkhov-400-26px: 400 26px "Volkhov";

$font-volkhov-700-14px-1_5: 700 14px/1.5 "Volkhov";
$font-volkhov-700-24px: 700 24px "Volkhov";
$font-volkhov-700-30px: 700 30px "Volkhov";
$font-volkhov-700-32px: 700 32px "Volkhov";
$font-volkhov-700-35px: 700 35px "Volkhov";
$font-volkhov-700-35px-1_29: 700 35px/1.29 "Volkhov";
$font-volkhov-700-36px-1_27: 700 36px/1.27 "Volkhov";
$font-volkhov-700-38px: 700 38px "Volkhov";
$font-volkhov-700-40px: 700 40px "Volkhov";
$font-volkhov-700-47px-1_3: 700 47px/1.3 "Volkhov";
$font-volkhov-700-50px: 700 50px "Volkhov";
$font-volkhov-700-50px-1_2: 700 50px/1.2 "Volkhov";
$font-volkhov-700-60px: 700 60px "Volkhov";

// Cambon
$font-cambon-400-80px-1_5: 400 80px/1.5 "Cambon";

// Inter
$font-inter-400-14px: 400 14px "Inter";
$font-inter-600-16px: 600 16px "Inter";
$font-inter-500-18px: 400 18px "Inter";

// Open-sans
$font-opensans-700-20px-1_36: 700 20px/1.36 "Open Sans";