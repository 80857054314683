@import "styles/font_variables.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

/*
 * Add font variables as Tailwind CSS
*/

@layer components {
  $font-variables: (
    "poppins-300-10px": $font-poppins-300-10px,
    "poppins-300-14px": $font-poppins-300-14px,
    "poppins-300-16px": $font-poppins-300-16px,
    "poppins-300-18px": $font-poppins-300-18px,

    "poppins-400-12px": $font-poppins-400-12px,
    "poppins-400-14px": $font-poppins-400-14px,
    "poppins-400-14px-1_7": $font-poppins-400-14px-1_7,
    "poppins-400-16px": $font-poppins-400-16px,
    "poppins-400-16px-1_87": $font-poppins-400-16px-1_87,
    "poppins-400-17px": $font-poppins-400-17px,
    "poppins-400-18px": $font-poppins-400-18px,
    "poppins-400-20px": $font-poppins-400-20px,
    "poppins-400-25px-1_84": $font-poppins-400-25px-1_84,

    "poppins-500-10px": $font-poppins-500-10px,
    "poppins-500-12px": $font-poppins-500-12px,
    "poppins-500-14px": $font-poppins-500-14px,
    "poppins-500-14px-1_24": $font-poppins-500-14px-1_24,
    "poppins-500-15px": $font-poppins-500-15px,
    "poppins-500-16px": $font-poppins-500-16px,
    "poppins-500-18px": $font-poppins-500-18px,

    "poppins-600-10px": $font-poppins-600-10px,
    "poppins-600-14px": $font-poppins-600-14px,
    "poppins-600-14_4px": $font-poppins-600-14_4px,
    "poppins-600-15px": $font-poppins-600-15px,
    "poppins-600-16px": $font-poppins-600-16px,
    "poppins-600-18px": $font-poppins-600-18px,
    "poppins-600-18px-1_5": $font-poppins-600-18px-1_5,
    "poppins-600-20px": $font-poppins-600-20px,
    "poppins-600-24px": $font-poppins-600-24px,
    "poppins-600-28px": $font-poppins-600-28px,
    "poppins-600-32px": $font-poppins-600-32px,
    "poppins-600-36px-1_27": $font-poppins-600-36px-1_27,

    "poppins-700-9_5px": $font-poppins-700-9_5px,

    "volkhov-700-24px": $font-volkhov-700-24px,
    "volkhov-700-40px": $font-volkhov-700-40px,
    "volkhov-700-38px": $font-volkhov-700-38px,
    "volkhov-700-35px": $font-volkhov-700-35px,

    "inter-600-16px": $font-inter-600-16px,
    "inter-500-18px": $font-inter-500-18px,
  );

  @each $font-variable in map-keys($font-variables) {
    .custom-text-#{$font-variable} {
      font: map-get($font-variables, $font-variable);
    }
  }

  /**
    * custom scroll
    */

  .custom-scrollbar {
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 20px;
    }
  }
}
